//@ts-nocheck
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
// const { baseURL } = require("../../../../packages/framework/src/config.js");
// Customizable Area End
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { pdfjs } from "react-pdf";


pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const configJSON = require("./config");

const BASE_URL = "https://9bcb-2409-40c1-33-b897-69ea-9efe-857c-d7da.ngrok-free.app/";
export interface Props {
  navigation: any;
  history: any;
  location: any;
  classes?: any;
}

export interface S {
  // Customizable Area Start
  selectFile: boolean,
  url: any
  selectedDocs: any[],
  copySelectedDocs: any[],
  responseData: any[],
  value: number,
  indexes: number,
  allKeywords: any[],
  onlyKeywords: any[],
  checkedKeyword: boolean,
  checkedKeywordIds: any[],
  keywordText: string,
  firstIntialLoad: boolean,
  unmatchedKeaword: any[],
  pdfValidationPopUp: any,
  childRefRefArray: any[],
  loading: boolean,
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class DocController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getApiId = ""
  postApiId = ""
  getAllKeywordsApiId = ""
  keywordRemoveCallId = ""
  keywordAddId = ""
  removeDocId = ""
  //documentRef: any;

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
  

    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];

    this.state = {
      // Customizable Area Start
      selectFile: false,
      url: '',
      selectedDocs: [],
      copySelectedDocs: [],
      responseData: [],
      value: 0,
      indexes: 0,
      allKeywords: [],
      onlyKeywords: [],
      checkedKeyword: false,
      checkedKeywordIds: [],
      keywordText: '',
      firstIntialLoad: true,
      unmatchedKeaword: [],
      pdfValidationPopUp: false,
      childRefRefArray: [],
      loading:false
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async componentWillMount() {
    // Customizable Area Start

    // Customizable Area End
  }

  handleChildRef = (ref: any, index: string | number) => {
    this.setState({
      childRefRefArray: [...this.state.childRefRefArray, ref]
    })
    // Access and use the childRefs array as needed
    console.log("handleChildRef :: ", this.state.childRefRefArray);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if (apiRequestCallId === this.getApiId) {
        this.handleGetApi(responseJson);
      }
      if (apiRequestCallId === this.postApiId) {
        this.handlePostApi(responseJson);
      }
      if (apiRequestCallId === this.getAllKeywordsApiId) {
        this.handleKeywordsResponse(responseJson);
      }
      if ((apiRequestCallId === this.keywordRemoveCallId) || (apiRequestCallId === this.keywordAddId)) {
        this.getAllKeywords();
      }
      if (apiRequestCallId === this.removeDocId) {
        this.setState({ indexes: 0 })
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleTextChange = (event: any) => {
    this.setState({ keywordText: event.target.value });
  };

  handleGetApi = (responseJson: any) => {
    if (responseJson) {
      this.setState({ responseData: responseJson.data.attributes.documents })
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }

  handlePostApi = (responseJson: any) => {
    if (responseJson) {
      this.setState({ responseData: responseJson.data.attributes.documents })

    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }


  handleExportPDF = async (childRef: any) => {
    const { numPages, fileName, canvasRef } = childRef;
    const pdf = new jsPDF("p", "mm", "a4");
    const totalPages = numPages || 0;

    for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
      const pageElement = canvasRef.querySelector(`.react-pdf__Page[data-page-number="${pageNumber}"]`) as HTMLElement;
      if (!pageElement) continue; // Skip if page element not found

      const canvas = await html2canvas(pageElement, 
        {
        useCORS: true,
        scale: 2,
      });

      if (pageNumber > 1) {
        pdf.addPage();
      }
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const imgWidth = pdf.internal.pageSize.getWidth();
      const imgHeight = (canvas.height * imgWidth)/ canvas.width;
      pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight, "", "FAST");
    }

    const saveAs = `${fileName}.pdf`;
    return pdf.save(saveAs);
  };
 
  handleDownload = async (event: any) => {
    const exportPromises = [];

    for (const childRef of this.state.childRefRefArray) {
      exportPromises.push(
        this.handleExportPDF(childRef).catch((error) => {
          console.error("Error downloading PDF:", error);
          return Promise.reject(error);
        })
      );
    }
  }

  handleDocumentRemove = (Item: any) => {
    let removeToken = localStorage.getItem("loginSuccessToken")
    const header = {
      "Content-Type": "application/json",
      "token": removeToken
    };
    const attrs = {
      id: Item
    };
    const data = {
      attributes: attrs,
    };
    const httpBody = {
      data: data
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeDocId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/remove_document`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `DELETE`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    const updatedResponseData = this.state.responseData.filter(doc => doc.id !== Item);
    this.setState({ responseData: updatedResponseData });
    if (updatedResponseData.length === 0) {
      this.setState({
        unmatchedKeaword: []
      })
    }
    return true;
  }

  handleKeywordAddition = (event?: any) => {
    if (event.key === 'Enter' || event === 'applied') {
      const keywords = this.state.keywordText.split(',');
      const keyword = keywords.map(i => i.trim())
      this.setState({ keywordText: '' });
      let token = localStorage.getItem("loginSuccessToken")
      const header = {
        "Content-Type": "application/json",
        "token": token
      };
      const attrs = {
        name: keyword
      };
      const data = {
        type: "keyword",
        attributes: attrs,
      };
      const httpBody = {
        data: data
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.keywordAddId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_keywordsearch/keywords`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }

  handleKeywordRemove = (Item: any) => {
    let removeToken = localStorage.getItem("loginSuccessToken")
    const header = {
      "Content-Type": "application/json",
      "token": removeToken
    };

    const data = {
    };
    const httpBody = {
      data: data
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.keywordRemoveCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_keywordsearch/keywords/${Item}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `DELETE`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  removeExtension = (filename: string) => {
    const name = filename?.substring(0, filename.lastIndexOf('.'));
    return name?.length > 15 ? name.substring(0, 15) : name;
  }

  handleKeywordsResponse = (responseJson: any) => {
    if (responseJson?.data?.length === 0) {
      this.setState({ unmatchedKeaword: [] })
    }
    if (responseJson) {
      if (this.state.firstIntialLoad) {
        const tempAllKeywords = responseJson.data.map((item: { id: any; name: any; border_color: string, keyword_color: string; }) => {
          return {
            id: item.id,
            name: item.name,
            border_color: item.border_color,
            keyword_color: item.keyword_color,
            checked: true
          }
        });
        this.setState({ allKeywords: tempAllKeywords, firstIntialLoad: false })
        this.setState({ checkedKeyword: tempAllKeywords.every((el: { checked: any; }) => el.checked) });
        this.setState(prev => { this.setState({ onlyKeywords: prev.allKeywords }) })
        return
      }
      const tempAllKeywords = responseJson.data.map((item: { id: any; name: any; border_color: string, keyword_color: string; }) => {
        const itemCheck = this.state.allKeywords.find(el => el.id === item.id)?.checked;
        return {
          id: item.id,
          name: item.name,
          border_color: item.border_color,
          keyword_color: item.keyword_color,
          checked: Boolean(itemCheck === undefined ? true : itemCheck)
        
        }
      });
      this.setState({ allKeywords: tempAllKeywords })
      this.setState({ checkedKeyword: tempAllKeywords.every((el: { checked: any; }) => el.checked) });
      this.setState(prev => { this.setState({ onlyKeywords: prev.allKeywords }) })
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }

  handleChange = (e: any, newValue: any) => {
    this.setState({ value: newValue });
  };

  onChangeCheckbox = (event: any, id: any) => {
    const tempAllKeywords = this.state.allKeywords.map(item => {
      if (item.id === id) {
        if (!item.checked) {
          this.setState(prev => { this.setState({ onlyKeywords: [...prev.onlyKeywords, item] }) })
        } else {
          this.setState(prev => {
            const newKeywords = prev.onlyKeywords.filter(keyword => keyword.id !== item.id)
            return { onlyKeywords: newKeywords }
          })
        }
        return {
          ...item,
          checked: !item.checked
        }
      } else {
        return item
      }
    })
    this.setState({ allKeywords: tempAllKeywords });
    this.setState({ checkedKeyword: tempAllKeywords.every(el => el.checked) });
    this.setState({ onlyKeywords: tempAllKeywords });
    const anyChecked = tempAllKeywords.some(el => el.checked);
    if (!anyChecked) {
      this.setState({ unmatchedKeaword: [] })
    }
  }

  onChangeSelectAllCheckbox = (event: any) => {
    this.setState({ checkedKeyword: event.target.checked });
    this.setState({
      allKeywords: this.state.allKeywords.map(item => {
        return {
          ...item,
          checked: event.target.checked
        }
      })
    });
    if (event.target.checked) {
      this.setState(prev => { this.setState({ onlyKeywords: prev.allKeywords }) });
    } else {
      this.setState({ onlyKeywords: [] });
      this.setState({ unmatchedKeaword: [] })
    }
  }

  onFileChange = (e: any) => {
    this.onUploadFile(e.target.files);
  };
  PdfPopUpclose = () => {
    if (this.state.pdfValidationPopUp === true) {
      this.setState({
        pdfValidationPopUp: false
      })
    }
  }
  onUploadFile = (fileList: any) => {
    let file: any[] = [...fileList];
    if (this.state.responseData.length) {
      const arr = this.state.responseData.map((item: any) => item.file_name)
      file = Array.from(file).filter(name => (!arr.includes(name.name)))
      {
        this.state.responseData.map((item: any) => {
          for (const file of fileList) {
            if (file.name === item?.file_name) {
              this.setState({ pdfValidationPopUp: true })
              // alert('no')
            }
          }
        })
      }
    }
    if (!file.length) return
    const formData = new FormData();

    for (let i = 0; i < file.length; i++) {
      formData.append('data[documents][]', file[i]);
    }

    const header = {
      token: localStorage.getItem("loginSuccessToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadFile
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  async componentDidMount() {

    let token = localStorage.getItem('loginSuccessToken')

    if (!token) {
      this.props.navigation.navigate('LoginAccount')
    } else {
      this.getUploadedFile();
      this.getAllKeywords();
    }
  }

  getUploadedFile = () => {

    const header = {
      token: localStorage.getItem("loginSuccessToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFileUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  };

  getAllKeywords = () => {
    const header = {
      token: localStorage.getItem("loginSuccessToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllKeywordsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allKeyworeds
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Customizable Area End

}