Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "DocumentOpener";
exports.labelBodyText = "DocumentOpener Body";
exports.getFileUrl = "account_block/accounts";
exports.uploadFile = "account_block/accounts/upload_document"
exports.allKeyworeds = "bx_block_keywordsearch/keywords"

exports.btnExampleTitle = "CLICK ME";

exports.openFromDevice = "Open from device";
exports.openWithUrl = "Open with URL";
// Customizable Area End